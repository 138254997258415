import { React, useState, useEffect } from 'react';
import { Fragment } from 'react'
import {
  XIcon,
  CalendarIcon,
  ChevronDownIcon,
  SaveIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import {useParams} from "react-router-dom";
import Spacer from './individual/Spacer';
import EditTable from './individual/EditTable';
import Title from './individual/Title';
import Divider from './individual/Divider';
import { Link, useHistory } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function EditClient() {
  const id = useParams()
  const [client, setClient] = useState([])
  const [boxes, setBoxes] = useState([])
  let history = useHistory()
  const [effect, setEffect] = useState(0);

  const editInput = (e) => {
    let localboxes = boxes;
    for( var i = 0, len_i = localboxes.length; i < len_i; i++ ) {
        if (localboxes[i][0] == e.target.getAttribute('identifier')) {
            localboxes[i][1] = e.target.value
        }
    }
    setBoxes([...localboxes]);    
  }

  const sumbitChanges = async() => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
        body: JSON.stringify({ array: boxes })
    };
    const res = await fetch('https://api.2022.leonidas.optimize-it.be/carton/update/client/', requestOptions);
    const data = await res.json();
    if (data === "OK") {
        history.push("/viewClient/" + id.id)
    }
  }

  useEffect(() => {
      const getClient = async() => {
             const res = await fetch('https://api.2022.leonidas.optimize-it.be/client/' + id.id + '/info/', 
             { 
               headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
             }
           )
            const data = await res.json()
             setClient(data)
      }
      const getBoxes = async() => {
            const res = await fetch('https://api.2022.leonidas.optimize-it.be/client/' + id.id + '/boxes/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setBoxes(data)
        }
      getClient();
      getBoxes();
  }, [effect])

  const removeCarton = async(id, content) => {
      if (content) {
        alert("Zorg er eerst voor dat het karton leeg is voor je deze probeert te verwijderen...")
      } else {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
            body: JSON.stringify({ cartonid: id })
        };
        const res = await fetch('https://api.2022.leonidas.optimize-it.be/carton/remove/', requestOptions);
        const data = await res.json();
        if (data === "OK") {
            setEffect(effect + 1)
        }
      }
    }

  return ( 
    <div className="mx-24">
            <Spacer height={50} />
            <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{client[1]} wijzigen</h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {client[2]}
                </div>
                </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
                <span className="hidden sm:block">
                <Link to={"/viewClient/" + id.id}>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <XIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                        Annuleren
                    </button>
                </Link>
                </span>
                <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={sumbitChanges}
                    >
                        <SaveIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Wijzigingen opslaan
                    </button>
                </span>

                {/* Dropdown */}
                <Menu as="span" className="ml-3 relative sm:hidden">
                <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    More
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                            Edit
                        </a>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <a
                            href="#"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                            View
                        </a>
                        )}
                    </Menu.Item>
                    </Menu.Items>
                </Transition>
                </Menu>
            </div>
            </div>
            <Spacer height={50} />
            <Title text="Overzicht kartons" type="sub" />
            <Spacer height={15} />
            <Divider />
            <Spacer height={25} />
            <EditTable data={boxes} fields={['naam', 'inhoud']} edit={false} onChange={editInput} removeable={true} removeIcon={<XIcon className="ml-auto mr-3 h-5 w-5 text-red-500" aria-hidden="true" />} onClick={removeCarton} type="replace" />
    </div>
)};