import {React, useState, useEffect} from 'react';
import Stat from './individual/Stat';
import Divider from './individual/Divider';
import Spacer from './individual/Spacer';
import EmptyState from './individual/EmptyState';
import Table from './individual/Table';
import Title from './individual/Title';
import {
  UserIcon,
  AcademicCapIcon,
  LibraryIcon,
} from '@heroicons/react/outline'

export default function Clients() {
    const [clients, setClients] = useState([])
    const [classes, setClasses] = useState([])

    useEffect(() => {
        const getClients = async() => {
            const res = await fetch('https://api.2022.leonidas.optimize-it.be/client/list/info/', 
              { 
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
              }
            )
            const data = await res.json()
            console.log(data)
            setClients(data)
        }
        const getClasses = async() => {
          const res = await fetch('https://api.2022.leonidas.optimize-it.be/class/list/info/', 
          { 
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
          }
        )
          const data = await res.json()
          setClasses(data)
      }
        getClients();
        getClasses();
    }, [])

  return ( 
    <div className="mx-24">
      <Spacer height={50} />
      <Title text="Klanten" type="head" />
      <Spacer height={15} />
      <Divider />
      <Spacer height={25} />
      <div class="grid grid-cols-3 gap-4">
        <EmptyState text="Klant toevoegen" link="/addClient/" icon={<UserIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}/>
        <EmptyState text="School toevoegen" link="/addSchool/" icon={<LibraryIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}/>
        <EmptyState text="Klas toevoegen" link="/addClass/" icon={<AcademicCapIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />}/>
      </div>
      <Spacer height={50} />
      <Divider />
      <Spacer height={25} />
      <Title text="Klanten" type="secondary" />
      <Spacer height={15} />
      <Table data={clients} fields={['klantnaam', 'aantal kartons', 'aantal dozen', 'prod. suppl.']} edit={true} link="/viewClient/" />
      <Spacer height={45} />
      <Title text="Klassen" type="secondary" />
      <Spacer height={15} />
      <Table data={classes} fields={['school', 'klasnaam', 'aantal kartons', 'aantal dozen', 'prod. suppl.']} edit={true} link="/viewClass/" />
      <Spacer height={50} />
    </div>
)};