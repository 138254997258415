import {React, useState, useEffect} from 'react';
import Title from './individual/Title';
import Spacer from './individual/Spacer';
import Button from './individual/Button';
import  { useHistory } from 'react-router-dom'
import {
  PlusIcon,
  XIcon
} from '@heroicons/react/solid'

export default function AddClient() {
  const [newClient, setNewClient] = useState('')
  let history = useHistory();

  const addClient = async(type) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
      body: JSON.stringify({ klantnaam: newClient })
  };
  const res = await fetch('https://api.2022.leonidas.optimize-it.be/client/add/', requestOptions);
  const data = await res.json();
  if (type === "primary") {
    history.push('/viewClient/' + data)
  } else {
    
  }
  }

  return ( 
      <div className="mx-24">
      <Spacer height={50} />
      <Title text="Klant toevoegen" type="head" />
      <Spacer height={15} />
       <div id="addDefaultClient">
              <label htmlFor="klantnaam" className="block text-sm font-medium text-gray-700">
              Klantnaam
              </label>
              <div className="mt-1">
              <input
                  type="text"
                  name="klantnaam"
                  id="klantnaam"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Klantnaam"
                  onChange={event => setNewClient(event.target.value)}
              />
              </div>
          </div>
          <Spacer height={15} />
          <div className="float-right">
              <Button text="Opslaan en nieuwe klant toevoegen" icon={<XIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />} type="secondary" onClick={addClient} />
              <span className="ml-3" />
              <Button text="Opslaan en inkijken" icon={<PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />} type="primary" onClick={addClient}/>
          </div>
      </div>
  )
};